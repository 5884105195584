<template>
  <div>
    <div class="
        pa-1
        mb-0
        deep-orange
        lighten-2
        text-body-1 text-uppercase text-center
        white--text
        rounded-t
      " v-if="bannerMessage">
      {{ bannerMessage }}
    </div>

    <div class="pa-1 mb-0 white--text" v-else>_</div>
    <v-card :class="'mb-4 mx-auto ' + evelationClass" max-width="500" min-width="300" height="100%">
      <!-- <div class="topcorner" v-if="bannerMessage">{{ bannerMessage }}</div> -->
      <v-card-title>
        <!-- <v-container>
          <v-row
            justify="center"
            class="text-h4 text-center primary--text font-weight-medium"
          >
            <v-col>
              {{ title }}
              <v-icon v-if="icon" color="primary" class="ml-4">{{
                icon
              }}</v-icon>
            </v-col>
          </v-row>
        </v-container> -->

        <v-toolbar color="primary" dark flat>
          <v-spacer />
          <v-toolbar-title class="text-h5 font-weight-medium">

            <h2 class="text-h5 font-weight-medium">
              <v-icon v-if="icon" class="ml-4">{{ icon }}</v-icon>
              {{ title }}
            </h2>
          </v-toolbar-title>
          <v-spacer />
        </v-toolbar>
      </v-card-title>
      <!-- <v-divider /> -->
      <v-card-text>
        <v-container fluid>
          <v-row justify="center" align="center" class="text-h5 text-center mb-6" v-if="subTitle">{{ subTitle }}</v-row>

          <v-row justify="center"><span class="text-h2">{{ price }}</span>
            <span class="text-h6">€</span><span class="ml-3">/ {{ periodicityLabel }}</span></v-row>
          <!-- <v-row justify="center" class="text-center mt-8 mb-8" v-else>
          <v-btn disabled text color="deep-orange accent-4" outlined style="transform:rotate(-45deg)">{{
            soonMessage ? soonMessage : "bientôt"
          }}</v-btn>
        </v-row> -->

          <v-row justify="center" class="mt-6"><v-icon small>mdi-plus</v-icon>
            <span class="text-body-2"><span class="font-weight-medium">{{ userPrice }}€</span> /
              {{ periodicityLabel }}
              par professionnel supplémentaire</span></v-row>
          <v-row justify="center" align="center" class="mt-6 mb-2">
            <!-- <v-chip
              ><v-icon color="orange" small class="mr-2">mdi-star</v-icon>100%
              déductible</v-chip
            > -->
          </v-row>
          <slot></slot>
          <v-spacer></v-spacer>
          <PricePlanLine class="pt-6" color="deep-orange lighten-3">
            <span class="font-weight-medium deep-orange--text text--lighten-3">Pas de frais d'installation</span>
          </PricePlanLine>
          <PricePlanLine color="deep-orange lighten-3">
            <span class="font-weight-medium deep-orange--text text--lighten-3">Pas de contrat fixe contraignant</span>
          </PricePlanLine>
          <PricePlanLine color="deep-orange lighten-3">
            <span class="font-weight-medium deep-orange--text text--lighten-3">Services inclus</span>
            <v-btn icon small class="ml-3" @click="showIncludedServices = !showIncludedServices">
              <v-icon class="font-weight-medium deep-orange--text text--lighten-3">{{
                showIncludedServices ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon>
            </v-btn>
          </PricePlanLine>
          <v-expand-transition>
            <div v-show="showIncludedServices">
              <PricePlanLine>
                <span>Sauvegarde quotidienne de vos données</span>
              </PricePlanLine>
              <PricePlanLine>
                <span>Mises à jour comprises</span>
              </PricePlanLine>
              <PricePlanLine>
                <span>Assistance</span>
              </PricePlanLine>
              <PricePlanLine>
                <span>Informations cryptées et serveur sécurisé</span>
              </PricePlanLine>
              <PricePlanLine>
                <span>Accessible sur tous vos navigateurs et appareils </span>
              </PricePlanLine>
            </div>
          </v-expand-transition>
          <v-row justify="center" align="center" class="mt-8">Essayez les 30 premiers jours gratuitement !</v-row>
          <v-row justify="center" class="mt-2">Aucun paiement demandé</v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-container fluid>
          <v-row align="end" justify="center">
            <v-btn :disabled="soon" text color="deep-orange accent-4" outlined
              :to="'/admin/register?bill_plan=' + billPlan">{{
                soon
                  ? soonMessage
                    ? soonMessage
                    : "bientôt disponible"
                  : "commencer"
              }}</v-btn>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import PricePlanLine from "@/components/Public/PricePlanLine";

export default {
  props: {
    title: String,
    subTitle: String,
    pricePerMonth: String,
    soon: Boolean,
    soonMessage: String,
    elevation: String,
    billPlan: String,
    periodicity: String,
    icon: String,
    bannerMessage: String,
  },
  components: {
    PricePlanLine,
  },
  data() {
    return {
      showIncludedServices: false,
    };
  },
  computed: {
    price() {
      if (this.periodicity == "monthly") return this.pricePerMonth;

      return parseInt(this.pricePerMonth) * 12 * (1 - 0.1);
    },
    userPrice() {
      if (this.periodicity == "monthly") return this.userPricePerMonth;

      return parseInt(this.userPricePerMonth) * 12 * (1 - 0.1);
    },
    userPricePerMonth() {
      return 5;
    },
    periodicityLabel() {
      return this.periodicity == "monthly" ? this.$t("month") : this.$t("year");
    },
    evelationClass() {
      return this.elevation ? `elevation-${this.elevation}` : "";
    },
  },
};
</script>

<style type="text/css">
.topcorner {
  position: absolute;
  top: 40px;
  right: -15px;
  z-index: 100;
  transform: rotate(45deg);
  background-color: #607d8b;
}
</style>