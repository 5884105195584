<template>
  <v-container v-if="true">
    <v-row justify="center" class="mt-4">
      <v-col sm="6" md="8" class="text-center">
        <h1 class="text-h4 font-weight-medium">
          Une offre claire<br />
          <span class="primary--text">adaptée à vos besoins.</span>
        </h1>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col sm="3" md="6">
        <div class="mt-3 text-center">
          Sélectionnez la période de votre plan de paiement
        </div>
        <div class="text-center">
          et découvrez le tarif de notre formule d'abonnement.
        </div>
      </v-col>
    </v-row>

    <v-row dense justify="space-around" class="mt-2">
      <v-radio-group v-model="periodicity" row>
        <v-col class="text-center">
          <v-chip color="orange lighten-4" :outlined="periodicity == 'annually'" link
            @click="periodicity = 'monthly'"><v-radio :label="$helpers.string.capitalizeI18N('monthly')"
              value="monthly"></v-radio>
          </v-chip>
        </v-col>
        <v-col class="text-center">
          <v-chip class="ml-6 pr-0" color="orange lighten-4" :outlined="periodicity == 'monthly'" link
            @click="periodicity = 'annually'"><v-radio :label="$helpers.string.capitalizeI18N('annually')"
              value="annually"></v-radio>
            <v-chip color="deep-orange lighten-2" class="font-weight-bold text-body-1 white--text" link
              @click="periodicity = 'annually'">-10%</v-chip>
          </v-chip>
        </v-col>
      </v-radio-group>
    </v-row>
    <v-row dense justify="space-around" class="mt-6">
      <!-- <PricePlanCard
        billPlan="essential"
        :title="'Starter'"
        subTitle="Profitez pleinement de tous les outils essentiels à votre gestion quotidienne"
        pricePerMonth="20"
        :periodicity="periodicity"
        icon="mdi-leaf"
      >
        <PricePlanLine>
          Clients <span class="font-weight-bold">illimités</span>
        </PricePlanLine>
        <PricePlanLine>
          Cours <span class="font-weight-bold">illimités</span>
        </PricePlanLine>
        <PricePlanLine>
          Abonnements <span class="font-weight-bold">illimités</span>
        </PricePlanLine>
        <PricePlanLine>
          <span class="font-weight-bold">Planning</span>
        </PricePlanLine>
        <PricePlanLine>
          <span class="font-weight-bold">Rapports d'activité</span>
        </PricePlanLine>
      </PricePlanCard> -->

      <!-- STARTER -->
      <PricePlanCard billPlan="starter" :title="'Starter'" subTitle="Réservation, planning & gestion administrative"
        pricePerMonth="10" :periodicity="periodicity" elevation="24" icon="mdi-leaf">
        <PricePlanLine>
          Clients <span class="font-weight-bold">illimités</span>
        </PricePlanLine>
        <PricePlanLine>
          Cours <span class="font-weight-bold">illimités</span>
        </PricePlanLine>
        <PricePlanLine>
          Abonnements <span class="font-weight-bold">illimités</span>
        </PricePlanLine>
        <PricePlanLine>
          <span class="font-weight-bold">Planning</span>
        </PricePlanLine>
        <PricePlanLine>
          <span class="font-weight-bold">Rapports d'activité</span>
        </PricePlanLine>
        <!-- <v-row justify="center" class="mt-6"
          ><v-chip color="success">Toute l'offre "Starter"</v-chip></v-row
        > -->
        <!-- <v-row justify="center" class="mt-6 mb-6"
          ><v-icon x-large>mdi-plus</v-icon></v-row
        > -->
        <PricePlanLine>
          <div>
            <span class="font-weight-bold">Page web</span> de votre activité
          </div>
        </PricePlanLine>
        <PricePlanLine>
          <span class="font-weight-bold">Horaires de vos cours en ligne</span>
        </PricePlanLine>
        <PricePlanLine>
          <v-chip color="orange lighten-2" text-color="white" label small class="pa-1">nouveau</v-chip>
          <span class="font-weight-bold ml-2">Réservations des cours en ligne</span>
        </PricePlanLine>
        <!-- <PricePlanLine>
          <v-chip color="orange lighten-2" text-color="white" label small class="pa-1">nouveau</v-chip>
          <span class="font-weight-bold ml-2">Paiement des cours en ligne</span>
        </PricePlanLine> -->
        <PricePlanLine>
          <v-chip color="orange lighten-2" text-color="white" label small class="pa-1">nouveau</v-chip>
          <span class="font-weight-bold ml-2">Communication automatisée</span>
          par email
        </PricePlanLine>
      </PricePlanCard>

      <!-- PRO -->
      <PricePlanCard billPlan="pro" :title="'Pro'" subTitle="L'offre la plus complète pour accueillir vos clients en toute tranquilité"
        bannerMessage="Le plus populaire" pricePerMonth="20" :periodicity="periodicity" elevation="24" icon="mdi-tree">
        <v-row justify="center" class="mt-6"><v-chip color="success" large class="text-body-1">Toute l'offre <v-icon left class="ml-3">mdi-leaf</v-icon>Starter</v-chip></v-row>
        <v-row justify="center" class="mt-8 mb-2"><v-icon x-large>mdi-plus</v-icon></v-row>
        <PricePlanLine>
          <v-chip color="orange lighten-2" text-color="white" label small class="pa-1">nouveau</v-chip>
          <span class="font-weight-bold ml-2">Paiement en ligne via Mollie</span>
        </PricePlanLine>
      </PricePlanCard>
      <!-- <PricePlanCard
        billPlan="full"
        :title="'Premium'"
        subTitle="L'offre la plus complète pour accueillir vos clients en toute tranquilité"
        pricePerMonth="50"
        :periodicity="periodicity"
        :soon="true"
        icon="mdi-cloud"
      >
        <v-row justify="center" class="mt-6"
          ><v-chip color="success">Toute l'offre "Starter"</v-chip></v-row
        >
        <v-row justify="center" class="mt-6"
          ><v-icon x-large>mdi-plus</v-icon></v-row
        >
        <v-row justify="center" class="mt-6"
          ><v-chip color="success">Toute l'offre "Pro"</v-chip></v-row
        >
        <v-row justify="center" class="mt-6"
          ><v-icon x-large>mdi-plus</v-icon></v-row
        >
        <PricePlanLine>
          <v-chip
            color="deep-orange lighten-2"
            text-color="white"
            label
            small
            class="pa-1"
            >bientôt</v-chip
          >
          <span class="font-weight-bold ml-2">Intégration du planning</span> sur
          votre propre site web
        </PricePlanLine>
        <PricePlanLine>
          <v-chip
            color="deep-orange lighten-2"
            text-color="white"
            label
            small
            class="pa-1"
            >bientôt</v-chip
          ><span class="font-weight-bold ml-2">Personnalisation</span> de votre
          page publique
        </PricePlanLine>
      </PricePlanCard> -->
    </v-row>
    <v-row align="center" justify="center" class="text-center text-caption mt-16">
      Les tarifs affichés sont hors taxes.
    </v-row>
    <!-- <v-row
      align="center"
      justify="center"
      class="text-center text-caption mt-10"
    >
      * monplanning étant toujours en cours de développement, certaines
      fonctionnalités ainsi que l'offre "Complet" ne sont pas encore
      disponibles.
    </v-row> -->

    <v-row align="center" justify="center" class="text-center mt-10">
      <h2 class="text-h5">Votre abonnement, votre méthode de paiement.</h2>
    </v-row>
    <v-row justify="center" class="mt-2 text-center">
      <v-col sm="4">
        monplanning vous propose une interface de paiement ultra sécurisée sur
        laquelle vous pouvez payer en toute tranquillité avec VISA, Mastercard
        ou Bancontact.
      </v-col>
    </v-row>
    <v-row justify="center" class="mt-5">
      <v-spacer />
      <v-col sm="1">
        <v-img src="/static/image/paymenttypes/visa.png" max-height="85" max-width="120" />
      </v-col>
      <v-col sm="1">
        <v-img src="/static/image/paymenttypes/mastercard.png" max-height="85" max-width="120" />
      </v-col>
      <v-col sm="1">
        <v-img src="/static/image/paymenttypes/bancontact.png" max-height="85" max-width="120" />
      </v-col>
      <v-spacer />
    </v-row>
    <v-row justify="center" class="mt-10 font-weight-medium"> Mollie </v-row>
    <v-row justify="center" class="mt-2 text-caption text-center">
      * Tous vos achats sur monplanning sont assurés par la plateforme de
      paiement Mollie.
    </v-row>

    <v-row justify="center">
      <BenefitsQuickSummary class="mt-12 mb-12" />
    </v-row>
    <v-row justify="center" class="mt-12">
      <v-col sm="6">
        <QuickHelp />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col sm="6">
        <v-btn :to="getLink('helpcenter')" color="primary">Visiter notre centre d'aide</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PricePlanCard from "@/components/Public/PricePlanCard";
import PricePlanLine from "@/components/Public/PricePlanLine";
import QuickHelp from "@/components/Public/HelpCenter/Components/QuickHelp";
import BenefitsQuickSummary from "@/components/Public/BenefitsQuickSummary.vue";

export default {
  name: "Pricing",
  components: {
    PricePlanCard,
    PricePlanLine,
    QuickHelp,
    BenefitsQuickSummary,
  },
  data() {
    return {
      periodicity: "monthly",
    };
  },
  methods: {
    getLink(target) {
      return this.$helpers.router.getPublicLink(target);
    },
  },
};
</script>
