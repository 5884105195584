<template>
  <div class="ma-4" v-bind="$attrs">
    <v-row no-gutters align="center">
      <v-col cols="1" class="shrink">
        <v-icon :color="color">mdi-check-circle-outline</v-icon></v-col
      >
      <v-col class="ml-2">
        <h3 class="font-weight-regular"><slot></slot></h3>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "PricePlanLine",
  props: {
    color: String,
  },
};
</script>